<template>
  <div>

    您好！这是登录默认页面1111111111

  </div>
</template>

<script>

export default {

name: "welcome"

}
</script>

<style scoped>

</style>
